import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Activity,
    Schema,
    Interaction,
} from '../../../lib/custom-activity/eventTypes';

export interface JourneyState {
    // Source of truth data coming from the Journey
    activity: Activity | null;
    schema: Schema | null;
    interaction: Interaction | null;
}

const initialState: JourneyState = {
    activity: null,
    schema: null,
    interaction: null,
};

/**
 * This slice manages the state of the current activity and journey.
 */
export const journeySlice = createSlice({
    name: 'journey',
    initialState,
    reducers: {
        updateActivity: (
            state: JourneyState,
            activity: PayloadAction<Activity>,
        ) => {
            state.activity = activity.payload;
        },
        updateSchema: (state: JourneyState, schema: PayloadAction<Schema>) => {
            state.schema = schema.payload;
        },
        updateInteraction: (
            state: JourneyState,
            interaction: PayloadAction<Interaction>,
        ) => {
            state.interaction = interaction.payload;
        },
    },
});

export function getCurrentDiscountCode(
    activity: Activity | null,
): string | null {
    if (activity !== null && activity.arguments.execute.inArguments) {
        const existingSelection =
            activity.arguments.execute.inArguments[0].discount ??
            activity.arguments.execute.inArguments[0].discountCode;

        if (existingSelection.split('.').length == 3) {
            return existingSelection.split('.')[1];
        }
    }

    return null;
}

export const { updateActivity, updateSchema, updateInteraction } =
    journeySlice.actions;
