/**
 * This module manages the Postmonger session for interacting with the Journey canvas.
 *
 * This gives us a single Postmonger instance, and lets us work with our own datatypes.
 */

import Postmonger from 'postmonger';
import {
    Activity,
    OnRequestedSchemaData,
    Schema,
} from '../custom-activity/eventTypes';

type ListenEvent =
    | 'onSchema'
    | 'onActivity'
    | 'onInteractionDefaults'
    | 'onInteraction';
type TriggerEvent =
    | 'ready'
    | 'requestSchema'
    | 'requestInteractionDefaults'
    | 'requestInteraction'
    | 'setActivityDirtyState'
    | 'updateActivity'
    | 'requestInspectorClose';

export const PostmongerSession = new Postmonger.Session();

let nextId = 0;
const callbacks: { [id: number]: (data?: any) => any } = {};
const events: { [event: string]: number[] } = {};

export function trigger(event: TriggerEvent, data?: any) {
    console.log(`----------------- triggering:${event} ---------------------`);
    if (data !== undefined) {
        console.log(JSON.stringify(data, null, 4));
        console.log(
            '--------------------------------------------------------------',
        );
    }

    PostmongerSession.trigger(event, data);
}

export function subscribe(
    event: ListenEvent,
    callback: (data?: any) => any,
): number {
    const id = nextId++;
    callbacks[id] = callback;

    if (!(event in events)) {
        events[event] = [];
    }
    events[event].push(id);

    return id;
}

export function unsubscribe(event: ListenEvent, id: number) {
    if (!(id in callbacks) || !(event in events)) {
        return;
    }

    const eventIdx = events[event].findIndex((i) => i === id);
    delete callbacks[id];
    events[event] = events[event].splice(eventIdx, 1);
}

function InvokeCallbacks(event: ListenEvent, data: any = undefined) {
    const callbacksToInvoke = events[event];
    if (callbacksToInvoke !== undefined) {
        for (let id of callbacksToInvoke) {
            callbacks[id](data);
        }
    }
}

// Listen for postmonger updates
let activity: Activity | null = null;
export function GetActivity(): Activity | null {
    return activity;
}
PostmongerSession.on('initActivity', (payload: Activity) => {
    console.log('! Activity Received', JSON.stringify(payload));
    activity = payload;

    InvokeCallbacks('onActivity', payload);
});

let schema: Schema | null = null;
export function GetSchema(): Schema | null {
    return schema;
}
PostmongerSession.on('requestedSchema', (payload: OnRequestedSchemaData) => {
    console.log('! Schema Received', JSON.stringify(payload.schema));
    schema = payload.schema;

    InvokeCallbacks('onSchema', payload.schema);
});

PostmongerSession.on('requestedInteractionDefaults', (payload: any) => {
    console.log(
        '! Interaction Defaults Received',
        JSON.stringify(payload['schema']),
    );

    InvokeCallbacks('onInteractionDefaults', payload);
});

PostmongerSession.on('requestedInteraction', (payload: any) => {
    console.log('! Schema Received', JSON.stringify(payload['schema']));

    InvokeCallbacks('onInteraction', payload);
});

PostmongerSession.trigger('ready');
PostmongerSession.trigger('requestSchema');
PostmongerSession.trigger('requestInteractionDefaults');
PostmongerSession.trigger('requestInteraction');
