import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataExtensionField } from '../../../lib/custom-activity/dataExtensionTypes';
import { Activity, Schema } from '../../../lib/custom-activity/eventTypes';
import { trigger } from '../../../lib/postmonger/postmongerManager';

export interface ConfigurationPageState {
    externalKey: string | null;

    dirty: boolean;
}

const initialState: ConfigurationPageState = {
    externalKey: null,

    dirty: false,
};

interface DoneArgs {
    activity: Activity;
    schema: Schema;
}

/**
 * This slice manages the state of the ConfigurationPage
 */
export const configurationPageSlice = createSlice({
    name: 'configurationPage',
    initialState,
    reducers: {
        externalKeyChanged: (
            state: ConfigurationPageState,
            action: PayloadAction<string>,
        ) => {
            const externalKey = action.payload;

            if (state.externalKey !== externalKey) {
                state.externalKey = externalKey;

                state.dirty = true;
                trigger('setActivityDirtyState', true);
            }
        },
        done: (
            state: ConfigurationPageState,
            action: PayloadAction<DoneArgs>,
        ) => {
            // Build a new activity with the updated data
            const activity = JSON.parse(
                JSON.stringify(action.payload.activity),
            );
            const schema = action.payload.schema;

            if (!state.dirty) {
                console.warn('Nothing to save.');
                return;
            }

            if (activity === null || schema === null) {
                const nullField = activity === null ? 'Activity' : 'Schema';
                console.error(
                    `Unable to finalize activity. ${nullField} is not defined.`,
                );
                return;
            }

            if (state.externalKey === null || state.externalKey === '') {
                return;
            }

            addSecurityOptionsToExecuteArg(activity, state.externalKey);
            addSecurityOptionsToConfigurationArgument(
                activity,
                'save',
                state.externalKey,
            );
            addSecurityOptionsToConfigurationArgument(
                activity,
                'stop',
                state.externalKey,
            );
            addSecurityOptionsToConfigurationArgument(
                activity,
                'publish',
                state.externalKey,
            );
            addSecurityOptionsToConfigurationArgument(
                activity,
                'unpublish',
                state.externalKey,
            );
            addSecurityOptionsToConfigurationArgument(
                activity,
                'validate',
                state.externalKey,
            );

            activity.metaData['isConfigured'] = true;

            trigger('updateActivity', activity);
            trigger('requestInspectorClose');
        },
        cancel: (state: ConfigurationPageState) => {
            // Tell Journey Builder that this activity has no changes.
            // we wont be prompted to save changes when the inspector closes
            trigger('setActivityDirtyState', false);

            // now request that Journey Builder closes the inspector/drawer
            trigger('requestInspectorClose');
        },
    },
});

export const { externalKeyChanged, done, cancel } =
    configurationPageSlice.actions;

function findField(schema: Schema, key: string): DataExtensionField | null {
    return schema.find((field) => field.name === key) || null;
}

/**
 * Adds or updates an inArgument with the name `argument` and value `value`.
 * @param activity The activity to update
 * @param argument The argument name
 * @param value The argument value
 * @returns Whether anything was changed.
 */
function addSecurityOptionsToExecuteArg(
    activity: Activity,
    externalKey: string,
): boolean {
    activity.arguments.execute.useJwt = false;
    activity.arguments.execute['securityOptions'] = {
        securityType: 'securityContext',
        securityContextKey: externalKey,
    };

    return true;
}

function addSecurityOptionsToConfigurationArgument(
    activity: Activity,
    arg: 'save' | 'stop' | 'publish' | 'unpublish' | 'validate',
    externalKey: string,
): boolean {
    const configArgs = activity?.configurationArguments;
    if (configArgs === undefined) {
        return false;
    }

    const argToConfigure = configArgs[arg];
    if (argToConfigure === undefined) {
        return false;
    }

    argToConfigure.useJwt = false;
    argToConfigure.securityOptions = {
        securityType: 'securityContext',
        securityContextKey: externalKey,
    };

    return true;
}
