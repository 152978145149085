import Postmonger from 'postmonger';
import { Activity } from '../lib/custom-activity/eventTypes';

declare global {
    interface Window {
        jb: JB;
    }
}

interface JB {
    ready: () => void;
}

/**
 * Set up the test harness if we are running on localhost, otherwise does nothing.
 *
 * This sets ups a Postmonger session that emulates how Journey Builder works. You can call jb.ready()
 * from the console to kick off the initActivity event with a mock activity object
 */
export function setupExampleTestHarness() {
    const isLocalhost =
        location.hostname === 'localhost' || location.hostname === '127.0.0.1';
    if (!isLocalhost) {
        // don't load the test harness functions when running in Journey Builder
        return;
    }

    const jbSession = new Postmonger.Session();
    const jb = getJbStub();
    window.jb = jb;

    jbSession.on('setActivityDirtyState', function (value: any) {
        console.log('[echo] setActivityDirtyState -> ', value);
    });

    jbSession.on('requestInspectorClose', function () {
        console.log('[echo] requestInspectorClose');
    });

    jbSession.on('updateActivity', function (activity: any) {
        console.log(
            '[echo] updateActivity -> ',
            JSON.stringify(activity, null, 4),
        );
    });

    jbSession.on('ready', function () {
        console.log('[echo] ready');
        console.log(
            '\tuse jb.ready() from the console to initialize your activity',
        );
    });

    jbSession.on('requestSchema', function () {
        console.log('[echo] requestSchema');
    });

    // fire the ready signal with an example activity
    jb.ready = function () {
        jbSession.trigger('initActivity', GetSimulatedActivity());
        jbSession.trigger('requestedSchema', GetSimulatedSchema());
    };
}

function getJbStub(): JB {
    return {
        ready: () => {},
    };
}

/**
 * Gets an activity that hasn't been configured yet.
 * @returns An activity object
 */
function GetSimulatedActivity(): Activity {
    return {
        name: 'JB JWT Custom Activity',
        id: 'GUID',
        key: 'REST-1',
        type: 'REST',
        arguments: {
            executionMode: '{{Context.ExecutionMode}}',
            definitionId: '{{Context.DefinitionId}}',
            activityId: '{{Activity.Id}}',
            contactKey: '{{Context.ContactKey}}',
            execute: {
                inArguments: [],
                outArguments: [],
                url: 'https://localhost:8080/execute',
                timeout: 10000,
                retryCount: 3,
                retryDelay: 1000,
                concurrentRequests: 5,
                securityOptions: {
                    securityType: 'securityContext',
                    securityContextKey: 'InitialJwtSecurityContext',
                },
            },
            startActivityKey: '{{Context.StartActivityKey}}',
            definitionInstanceId: '{{Context.DefinitionInstanceId}}'
        },
        configurationArguments: {
            save: {
                url: 'https://localhost:8080/save',
            },
            publish: {
                url: 'https://localhost:8080/publish',
            },
            unpublish: {
                url: 'https://localhost:8080/unpublish',
            },
            stop: {
                url: 'https://localhost:8080/stop',
            },
            validate: {
                url: 'https://localhost:8080/validate',
            }
        },
        metaData: {
            isConfigured: false,
        },
        editable: true
    };
}

/**
 * Gets an activity that's already been configured.
 * @returns Return an activity object.
 */
function GetSimulatedActivity_Configured() {
    return {
        name: 'Refresh Individual From Salesforce CDP',
        id: 'GUID',
        key: 'REST-2',
        type: 'REST',
        arguments: {
            executionMode: '{{Context.ExecutionMode}}',
            definitionId: '{{Context.DefinitionId}}',
            activityId: '{{Activity.Id}}',
            contactKey: '{{Context.ContactKey}}',
            execute: {
                inArguments: [
                    {
                        profileType: 'Individual',
                    },
                    {
                        subscriberKey: '{{Event.APIEvent-GUID.SubscriberKey}}',
                    },
                ],
                outArguments: [],
                url: 'https://localhost:8080/modules/cdp-refresh/execute',
                timeout: 10000,
                retryCount: 3,
                retryDelay: 1000,
                concurrentRequests: 5,
            },
            testExecute: '',
            startActivityKey: '{{Context.StartActivityKey}}',
            definitionInstanceId: '{{Context.DefinitionInstanceId}}',
            requestObjectId: '{{Context.RequestObjectId}}',
        },
        configurationArguments: {
            save: '',
            testSave: '',
            publish: {
                url: 'https://localhost:8080/modules/cdp-refresh/publish',
            },
            testPublish: '',
            unpublish: '',
            stop: {
                url: 'https://localhost:8080/modules/cdp-refresh/stop',
            },
            testStop: '',
            testUnpublish: '',
            partnerActivityId: '',
            validate: {
                url: 'https://localhost:8080/modules/cdp-refresh/validate',
            },
            testValidate: '',
            outArgumentSchema: {
                cdpData: {
                    dataType: 'Text',
                    isNullable: false,
                    direction: 'Out',
                    readOnly: false,
                    access: 'Visible',
                },
            },
        },
        metaData: {
            isConfigured: true,
        },
        schema: {
            arguments: {
                execute: {
                    inArguments: [
                        {
                            subscriberKey: {
                                dataType: 'Text',
                                isNullable: false,
                                direction: 'In',
                                readOnly: false,
                                access: 'Visible',
                            },
                            profileType: {
                                dataType: 'Text',
                                isNullable: false,
                                direction: 'In',
                                readOnly: false,
                                access: 'Visible',
                            },
                        },
                    ],
                    outArguments: [
                        {
                            cdpData: {
                                dataType: 'Text',
                                isNullable: false,
                                direction: 'Out',
                                readOnly: false,
                                access: 'Visible',
                            },
                        },
                    ],
                },
            },
        },
        editable: true,
        outcomes: [
            {
                key: 'GUID',
                next: 'UPDATECONTACTDATAV2-1',
                arguments: {},
                metaData: {
                    invalid: false,
                },
            },
        ],
        errors: null,
    };
}

/**
 * Gets a schema payload from an activated CDP Segment
 */
function GetSimulatedSchema() {
    return {
        schema: [
            {
                key: 'Event.APIEvent-GUID.SubscriberKey',
                name: 'SubscriberKey',
                type: 'Text',
                length: 254,
                default: null,
                isNullable: null,
                isPrimaryKey: true,
            },
            {
                key: 'Event.APIEvent-GUID.Id',
                name: 'Id',
                type: 'Text',
                length: 254,
                default: null,
                isNullable: true,
                isPrimaryKey: null,
            },
            {
                key: 'Event.APIEvent-GUID.EmailAddress',
                name: 'EmailAddress',
                type: 'EmailAddress',
                length: 254,
                default: null,
                isNullable: true,
                isPrimaryKey: null,
            },
            {
                key: 'Event.APIEvent-GUID.DataSourceId',
                name: 'DataSourceId',
                type: 'Text',
                length: 254,
                default: null,
                isNullable: true,
                isPrimaryKey: null,
            },
            {
                key: 'Event.APIEvent-GUID.DataSourceObjectId',
                name: 'DataSourceObjectId',
                type: 'Text',
                length: 254,
                default: null,
                isNullable: true,
                isPrimaryKey: null,
            },
        ],
    };
}
