import React from 'react';

export function DockedFooter(props: {
    canSubmit: () => boolean;
    cancel: () => any;
    done: () => any;
}) {
    return (
        <div
            className="slds-docked-form-footer"
            style={{ justifyContent: 'flex-end' }}
        >
            <button
                id="cancel"
                type="button"
                className="slds-button slds-button_neutral"
                onClick={props.cancel}
            >
                Cancel
            </button>
            <button
                id="done"
                disabled={!props.canSubmit()}
                type="button"
                className="slds-button slds-button_brand slds-m-right--medium"
                onClick={props.done}
            >
                Done
            </button>
        </div>
    );
}
