import React from 'react';
import { ConfigurationPage } from './features/configurationPage/configurationPage';
import { createRoot } from 'react-dom/client';
import { setupExampleTestHarness } from './testHarness';
import { store } from './store';
import { Provider } from 'react-redux';
import {
    GetActivity,
    GetSchema,
    subscribe,
} from '../lib/postmonger/postmongerManager';
import {
    updateActivity,
    updateSchema,
} from './features/journeyBuilder/journeySlice';
import { Activity, Schema } from '../lib/custom-activity/eventTypes';

// Set up some testing hooks before we mount the UI
setupExampleTestHarness();

/**
 * Connects our journeySlice to the postmonger lifecycle events, so that we can
 * receive data from the journey canvas.
 * 
 * The store can be updated asynchronously from Journey Builder. These are attached
 * here to avoid circular imports between the store and the postmonger manager.
 */
function attachGlobalAsyncStoreUpdaters() {
    // Update the store if Journey Builder has already provided our data.
    const currentActivity = GetActivity();
    if (currentActivity) {
        store.dispatch(updateActivity(currentActivity));
    }

    const currentSchema = GetSchema();
    if (currentSchema) {
        store.dispatch(updateSchema(currentSchema));
    }

    // Subscribe to new updates to keep the store in sync
    subscribe('onActivity', (activity: Activity) => {
        // Update the Redux store
        store.dispatch(updateActivity(activity));
    });

    subscribe('onSchema', (schema: Schema) => {
        // Update the Redux store
        store.dispatch(updateSchema(schema));
    });
}
attachGlobalAsyncStoreUpdaters();

// Mount the UI
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <ConfigurationPage />
    </Provider>,
);
