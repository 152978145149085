import React from 'react';
import { DockedFooter } from '../../components/dockedFooter';

import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    cancel,
    done,
    externalKeyChanged,
} from './configurationPageSlice';
import {
    Activity,
    ConfigurationArguments,
} from '../../../lib/custom-activity/eventTypes';

/**
 * This is a root page that lets the journey author provide their SecurityContext External Key, 
 * and configures the custom activity endpoints to use it.
 */
export function ConfigurationPage() {
    const externalKey = useAppSelector(
        (state) => state.configurationPage.externalKey,
    );
    const activity = useAppSelector((state) => state.journey.activity);
    const schema = useAppSelector((state) => state.journey.schema);
    const dirty = useAppSelector(
        (state) => state.configurationPage.dirty,
    );

    const dispatch = useAppDispatch();

    if (activity === null || schema === null) {
        return (
            <div className="slds-form slds-m-around--medium">
                Waiting for Journey Builder...
            </div>
        );
    }

    let externalKeyToShow = externalKey;
    if (externalKeyToShow === null) {
        externalKeyToShow = findExternalKeyFromActivity(activity) || '';
    }

    return (
        <div className="slds-form slds-m-around--medium">
            <div className="slds-form-element slds-m-bottom_small">
                <label
                    className="slds-form-element__label"
                    htmlFor="profile-type"
                >
                    SecurityContextKey
                </label>
                <div className="slds-form-element__control">
                    <div className="slds-input_container">
                        <input
                            className="slds-input"
                            id="external-key"
                            placeholder={externalKeyToShow}
                            onChange={(event) =>
                                dispatch(externalKeyChanged(event.target.value))
                            }
                        />
                    </div>
                </div>
            </div>
            <DockedFooter
                canSubmit={() => dirty}
                cancel={() => dispatch(cancel())}
                done={() =>
                    dispatch(
                        done({
                            activity: activity,
                            schema: schema,
                        }),
                    )
                }
            />
        </div>
    );
}

/**
 * Checks to see if all of the SecurityContextKeys in the config.json match, and if so, returns the current value.
 * 
 * @param activity The custom activity definition.
 * @returns The External Key that was found.
 */
function findExternalKeyFromActivity(activity: Activity): string | null {
    const executeValue =
        activity?.arguments?.execute?.securityOptions?.securityContextKey;
    const allValues: [string | undefined] = [executeValue];

    const configurationArguments = activity.configurationArguments;
    if (configurationArguments === undefined) {
        return executeValue || null;
    }

    if (configurationArguments.publish !== undefined) {
        allValues.push(
            configurationArguments.publish?.securityOptions?.securityContextKey,
        );
    }

    if (configurationArguments.unpublish !== undefined) {
        allValues.push(
            configurationArguments.unpublish?.securityOptions
                ?.securityContextKey,
        );
    }

    if (configurationArguments.save) {
        allValues.push(
            configurationArguments.save?.securityOptions?.securityContextKey,
        );
    }

    if (configurationArguments.stop) {
        allValues.push(
            configurationArguments.stop?.securityOptions?.securityContextKey,
        );
    }

    if (configurationArguments.validate) {
        allValues.push(
            configurationArguments.validate?.securityOptions
                ?.securityContextKey,
        );
    }

    for (const v of allValues) {
        if (v !== executeValue) {
            return null;
        }
    }

    return executeValue || null;
}
