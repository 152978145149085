import { configureStore } from '@reduxjs/toolkit';
import { journeySlice } from '../client/features/journeyBuilder/journeySlice';
import { configurationPageSlice } from './features/configurationPage/configurationPageSlice';

export const store = configureStore({
    reducer: {
        journey: journeySlice.reducer,
        configurationPage: configurationPageSlice.reducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
